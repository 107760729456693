// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
	margin: 0;
	/* padding: 0; */
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

* {
	text-decoration: none;
	/* box-sizing: border-box; */
}

.pagination-container {
	display: flex;
	justify-content: center;
	align-items: center;
	/* background: rgba(216, 73, 73, 0.2); */
	width: 100%;
	font-weight: 500;
	font-size: 1.25rem;
	margin-top: 1.5rem;
}

.pagination-container a {
	display: flex;
	justify-content: center;
	align-items: center;
	color: black;
	/* padding: 8px 14px; */
	height: 30px;
	width: 30px;
	text-decoration: none;
	transition: background-color 0.2s;
	cursor: pointer;
	color: #7e84a3;
}

.pagination-container a.active {
	color: white;
	border-radius: 4px;
	background-color: #72acff;
	/* border: 1px solid #7cbddb; */
}

.pagination-container a.disabled {
	opacity: 0.2;
}
.pagination-container a:hover:not(.active) {
	background-color: rgb(238, 238, 238);
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;CACC,SAAS;CACT,gBAAgB;CAChB;;YAEW;CACX,mCAAmC;CACnC,kCAAkC;AACnC;;AAEA;CACC;WACU;AACX;;AAEA;CACC,qBAAqB;CACrB,4BAA4B;AAC7B;;AAEA;CACC,aAAa;CACb,uBAAuB;CACvB,mBAAmB;CACnB,wCAAwC;CACxC,WAAW;CACX,gBAAgB;CAChB,kBAAkB;CAClB,kBAAkB;AACnB;;AAEA;CACC,aAAa;CACb,uBAAuB;CACvB,mBAAmB;CACnB,YAAY;CACZ,uBAAuB;CACvB,YAAY;CACZ,WAAW;CACX,qBAAqB;CACrB,iCAAiC;CACjC,eAAe;CACf,cAAc;AACf;;AAEA;CACC,YAAY;CACZ,kBAAkB;CAClB,yBAAyB;CACzB,+BAA+B;AAChC;;AAEA;CACC,YAAY;AACb;AACA;CACC,oCAAoC;AACrC","sourcesContent":["body {\n\tmargin: 0;\n\t/* padding: 0; */\n\tfont-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n\t\t'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n\t\tsans-serif;\n\t-webkit-font-smoothing: antialiased;\n\t-moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n\tfont-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n\t\tmonospace;\n}\n\n* {\n\ttext-decoration: none;\n\t/* box-sizing: border-box; */\n}\n\n.pagination-container {\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\t/* background: rgba(216, 73, 73, 0.2); */\n\twidth: 100%;\n\tfont-weight: 500;\n\tfont-size: 1.25rem;\n\tmargin-top: 1.5rem;\n}\n\n.pagination-container a {\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\tcolor: black;\n\t/* padding: 8px 14px; */\n\theight: 30px;\n\twidth: 30px;\n\ttext-decoration: none;\n\ttransition: background-color 0.2s;\n\tcursor: pointer;\n\tcolor: #7e84a3;\n}\n\n.pagination-container a.active {\n\tcolor: white;\n\tborder-radius: 4px;\n\tbackground-color: #72acff;\n\t/* border: 1px solid #7cbddb; */\n}\n\n.pagination-container a.disabled {\n\topacity: 0.2;\n}\n.pagination-container a:hover:not(.active) {\n\tbackground-color: rgb(238, 238, 238);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
