import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import * as S from './DockLikuController.style';

import MakeMotion from './MakeMotion';
import SaveMotion from './SaveMotion';
import PlayList from './PlayList';
import RobotState from './RobotState';

const DockLikuController = observer(({ controller, isVisible }) => {
	const [isAnimating, setIsAnimating] = useState(true);

	useEffect(() => {
		setIsAnimating(true);
		const timer = setTimeout(() => {
			setIsAnimating(false);
		}, 900);
		return () => clearTimeout(timer);
	}, [controller.click]);

	return (
		<S.Content isVisible={isVisible} isAnimating={isAnimating}>
			<MakeMotion />
			<SaveMotion isVisible={isVisible} />
			<S.Wrap>
				<PlayList />
				<RobotState />
			</S.Wrap>
		</S.Content>
	);
});

export default DockLikuController;
