import React, { useEffect, useRef, useState } from 'react';
import * as S from './customCursor.style';

const CustomCursor = () => {
	const [position, setPosition] = useState({ x: 0, y: 0 });
	const [isButtonHovered, setIsButtonHovered] = useState(false);
	const [isFullscreen, setIsFullscreen] = useState(false);
	const cursorRef = useRef(null);

	useEffect(() => {
		const updateFullscreenState = () => {
			setIsFullscreen(!!document.fullscreenElement);
		};

		// 전체 화면 상태 변경을 감지하기 위한 이벤트 리스너 등록
		document.addEventListener('fullscreenchange', updateFullscreenState);

		// 초기 전체 화면 상태 설정
		updateFullscreenState();

		return () => {
			// 컴포넌트가 언마운트될 때 이벤트 리스너 제거
			document.removeEventListener('fullscreenchange', updateFullscreenState);
		};
	}, []);

	useEffect(() => {
		let animation = null;

		const updatePosition = (e) => {
			const x = e.clientX;
			const y = e.clientY;

			if (cursorRef.current) {
				if (animation) {
					animation.cancel();
				}
				setPosition({ x, y });
				setIsButtonHovered([...e.target.classList].includes('hovered'));
				animation = cursorRef.current.animate(
					[
						{
							transform: `translate(calc(${position.x}px - 50%), calc(${position.y}px - 50%))`,
						},
						{ transform: `translate(calc(${x}px - 50%), calc(${y}px - 50%))` },
					],
					{
						duration: 100,
						fill: 'forwards',
					},
				);
				animation.commitStyles();
			}
		};

		// 전체 화면 모드가 아닐 때만 마우스 움직임 이벤트 리스너 등록
		if (!isFullscreen) {
			window.addEventListener('mousemove', updatePosition);
		}

		return () => {
			window.removeEventListener('mousemove', updatePosition);
			if (animation) {
				animation.cancel();
			}
		};
	}, [position, isFullscreen]);

	return (
		<S.CustomCursor
			ref={cursorRef}
			isButtonHovered={isButtonHovered}
			// 전체 화면 모드일 때 커스텀 커서 숨김
			style={{ display: isFullscreen ? 'none' : 'block' }}
		/>
	);
};

export default CustomCursor;
