import { makeAutoObservable, reaction, runInAction } from 'mobx';

class PersonaStore {
	rootStore;
	personaResponse = 'testResponse';

	constructor(root) {
		makeAutoObservable(this);
		this.rootStore = root;
		this.personaResponse = 'testResponse';

		reaction(
			() => this.rootStore.likuStore?.thisLiku?.persona,
			(persona) => {
				if (persona) {
					this.setPersonaResponse(`Updated: ${JSON.stringify(persona)}`);
				}
			},
		);
	}

	setPersonaResponse(_res) {
		runInAction(() => {
			this.personaResponse = _res;
		});
	}

	/**
	 *
	 * @param {Array<Object>} persona
	 * 	- key : value 쌍 배열
	 */
	publishPersona = (persona) => {
		const thisLiku = this.rootStore.likuStore?.thisLiku;
		console.log('publishPersona', thisLiku, persona);
		this.rootStore.mqttStore.publishMessage(`liku/${thisLiku.uuid}/persona`, [
			...persona,
		]);
	};
}

export default PersonaStore;
