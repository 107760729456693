import mqtt from 'mqtt/dist/mqtt';

const URL = 'wss://api.likuwithme.com:15675';
// 'ws://ec2-43-200-2-139.ap-northeast-2.compute.amazonaws.com:15675';
const QOS = { qos: 0 };
const OPTIONS = {
	keepalive: 30,
	protocolId: 'MQTT',
	protocolVersion: 4,
	clean: true,
	path: '/ws',
	reconnectPeriod: 1000,
	connectTimeout: 30 * 1000,
	will: {
		topic: 'WillMsg',
		payload: 'Connection Closed abnormally..!',
		retain: false,
		...QOS,
	},
	rejectUnauthorized: false,
	clientId: `mqttjs_${Math.random().toString(16).substr(2, 8)}`,
	username: 'liku',
	password: 'likuliku20',
	// username: 'admin',
	// password: '1',
};

const Mqtt = (topicHandlerStrategy, setIsConnected, callback) => {
	const client = mqtt.connect(URL, {
		...OPTIONS,
		transformWsUrl: (URL, OPTIONS, this),
	});

	client.on('connect', () => {
		console.log('Client connected:');
		setIsConnected(true);
	});
	client.on('close', () => {
		console.log('Client closed:');
		setIsConnected(false);
	});
	client.on('error', (error) => {
		console.error('Connection error: ', error);
		setIsConnected(false);
	});

	client.on('message', (topic, message) => {
		// const originMessage = message;
		const enc = new TextDecoder('utf-8');
		const arr = new Uint8Array(message);
		let decodeMessage;
		try {
			decodeMessage = JSON.parse(enc.decode(arr));
		} catch (error) {
			decodeMessage = enc.decode(arr);
		}

		console.log(`${topic}: ${JSON.stringify(decodeMessage)}`);

		// 메시지 핸들링을 위해 TopicHandlerStrategy 호출
		topicHandlerStrategy.handleMessage(topic, decodeMessage, message);
	});

	const reconnect = () => {
		disconnect();
		// this.connect();
		client?.reconnect(true, {}, () => {
			console.log('Client reconnect:');
		});
	};

	const disconnect = () => {
		if (client) {
			client.end(true, {}, () => {
				setIsConnected(false);
				console.log('client disconnected');
			});
		}
	};

	const publish = (topic, payload, options) => {
		if (client) {
			if (typeof payload === 'object') payload = JSON.stringify(payload);
			client.publish(topic, payload, { ...QOS, ...options }, (error) => {
				if (error) {
					console.log('Publish error: ', error);
				}
			});
			console.log('publish topic', topic);
			console.log('publish payload', payload);
		}
	};

	const subscribe = (topic) => {
		if (client) {
			client.subscribe(topic, QOS, (error) => {
				if (error) {
					console.log('Subscribe to topics error', error);
				}
			});
			console.log('subscribe', topic);
		}
	};

	const unsubscribe = (topic) => {
		if (client) {
			client.unsubscribe(topic, {}, (error) => {
				if (error) {
					console.log('unSubscribe to topics error', error);
				}
			});
			console.log('unsubscribe', topic);
		}
	};

	return {
		reconnect,
		disconnect,
		publish,
		subscribe,
		unsubscribe,
	};
};

export default Mqtt;
