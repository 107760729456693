import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import * as S from './CategoryComponent.style';

import CategoryTitleItem from './CategoryTitleItem';
import CategoryDetailBox from './CategoryDetailBox';
import { RootStoreContext } from '../../../App';
import useTagClickHandler from '../../../hooks/useTagClickHandler';
import { premiumTags } from '../../../data/categoryTagId';
import { useLocation, useNavigate } from 'react-router-dom';

const CategoryComponent = observer(() => {
	const { authStore, likuStore } = React.useContext(RootStoreContext);
	const [selectedItemId, setSelectedItemId] = useState(null);
	const [isCategoryHovered, setIsCategoryHovered] = useState(false);
	const [hoveredItemID, setHoveredItemID] = useState(null);
	const [showDetailBox, setShowDetailBox] = useState(false);
	const [isMouseOnBlackBackground, setIsMouseOnBlackBackground] =
		useState(false);
	const tagClickHandler = useTagClickHandler('unique');

	const handleItemClick = (itemId) => {
		console.log('itemId', itemId);
		setSelectedItemId((prevItemId) => {
			// 같은 아이템을 다시 클릭하면 선택 해제
			return prevItemId === itemId ? null : itemId;
		});
	};

	const handlePremiumItemClick = (tag) => {
		const checkMemberRole = (_roleId) => {
			const result = authStore.auth?.role?.some((role) => role.id === _roleId);
			// return result;
			return true;
		};

		if (tag.id === 55) {
			// aien role id = 9
			if (checkMemberRole(9)) {
				if (!likuStore.thisLiku) {
					return alert('로봇을 연결해주세요');
				}
				const currentDomain = window.location.hostname;
				if (currentDomain.includes('torooc.com')) {
					window.open('http://aien.torooc.com');
				} else if (currentDomain.includes('torooc.net')) {
					window.open('http://aien.torooc.net');
				} else {
					window.open('http://aien.torooc.net');
				}
			} else {
				alert('AIEN 서비스 구매 회원만 이용 가능합니다.');
			}
		} else if (tag.id === 42) {
			// 융합과학 role id = 7
			tagClickHandler(tag, 'search');
			if (checkMemberRole(7)) {
				tagClickHandler(tag, 'search');
			} else {
				alert('융합과학 서비스 구매 회원만 이용 가능합니다.');
			}
		} else if (tag.id === 40) {
			// 생각톡톡 role id = 8
			tagClickHandler(tag, 'search');
			if (checkMemberRole(8)) {
				tagClickHandler(tag, 'search');
			} else {
				alert('생각톡톡 서비스 구매 회원만 이용 가능합니다.');
			}
		}
	};

	const handleItemHover = (itemId) => {
		setHoveredItemID(itemId);
	};

	const handleMouseLeave = () => {
		setHoveredItemID(null);
	};

	const renderMainCategory = () => {
		let categoryTitles = [
			{ name: '언어', id: 1 },
			{ name: '영어', id: 2 },
			{ name: '미술', id: 3 },
			{ name: '음악', id: 4 },
			{ name: '과학', id: 5 },
			{ name: '안전', id: 6 },
			{ name: '역사', id: 7 },
			{ name: '리쿠 행사', id: 8 },
		];

		let seniorCategoryTitles = [
			{ name: '신체', id: 1 },
			{ name: '인지', id: 2 },
			{ name: '정서', id: 3 },
		];

		const role = authStore.auth?.role;
		if (role?.some((role) => role.name === 'senior')) {
			categoryTitles = seniorCategoryTitles;
		}

		return categoryTitles.map((item) => {
			return (
				<CategoryTitleItem
					key={item.id}
					item={item}
					selected={selectedItemId}
					onClick={handleItemClick.bind(this)}
					// onClick={() => handleItemClick(item.id)}
					onHover={() => {
						handleItemHover(item.id);
						setShowDetailBox(true);
						setIsMouseOnBlackBackground(false);
					}}
					isHovered={hoveredItemID === item.id}
					titleType={'main'}
					isMouseOnBlackBackground={isMouseOnBlackBackground}
				/>
			);
		});
	};

	const renderSubCategory = () => {
		const curRoles = authStore.auth?.role;
		console.log('curRoles', curRoles);
		if (!curRoles?.some((role) => role.name === 'kid')) {
			return;
		}

		return premiumTags.map((item) => (
			<CategoryTitleItem
				key={item.id}
				item={item}
				isPremium={true}
				selected={selectedItemId}
				onClick={() => handlePremiumItemClick(item)}
				onHover={() => {
					handleItemHover(item.id);
					setShowDetailBox(false);
				}}
				isHovered={hoveredItemID === item.id}
				titleType={'sub'}
			/>
		));
	};

	useEffect(() => {
		console.log(
			'[isCategoryHovered, hoveredItemID]',
			isCategoryHovered,
			hoveredItemID,
		);
	}, [isCategoryHovered, hoveredItemID]);

	useEffect(() => {
		console.log('[isMouseOnBlackBackground]', isMouseOnBlackBackground);
	}, [isMouseOnBlackBackground]);

	return (
		<S.Container
			onMouseEnter={() => setIsCategoryHovered(true)}
			onMouseLeave={() => {
				setIsCategoryHovered(false);
				handleMouseLeave();
			}}>
			<S.CategoryBox>
				{renderMainCategory()}
				{renderSubCategory()}
			</S.CategoryBox>
			{isCategoryHovered && showDetailBox ? (
				<CategoryDetailBox
					isHovered={isCategoryHovered}
					setIsHovered={setIsCategoryHovered}
					categoryID={hoveredItemID}
					setIsMouseOnBlackBackground={setIsMouseOnBlackBackground}
					setMainCategory={handleItemClick.bind(this)}
				/>
			) : null}
		</S.Container>
	);
});

export default CategoryComponent;
