import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { get } from '../../utils/Cookie';

import { Container, Nav } from './Layout.style';

const ManageLayout = () => {
	const navigation = useNavigate();
	const { pathname } = window.location;
	console.log('location', pathname);
	const name = get('name');
	const role = get('role');

	const isNotKid = (role) => {
		console.log('role', role);
		const isNotKid = !role?.some((r) => r.name === 'kid');
		const isAdmin = role?.some((r) => r.name === 'admin');
		return isNotKid || isAdmin;
	};
	return (
		<Container>
			{
				<Nav>
					<div
						className={pathname === '/custom' ? 'selected' : ''}
						onClick={() => {
							navigation('/custom');
						}}>
						리쿠 커스텀
					</div>
					<div className='dash'></div>
					<div
						className={pathname === '/manage' ? 'selected' : ''}
						onClick={() => {
							navigation('/manage');
						}}>
						사용자 관리
					</div>
					<div className='dash'></div>
					<div
						className={pathname === '/alarm' ? 'selected' : ''}
						onClick={() => {
							navigation('/alarm');
						}}>
						알람
					</div>
				</Nav>
			}
			<Outlet />
		</Container>
	);
};

export default ManageLayout;
