import React, { useState, useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import * as S from './SearchDetailBox.style';
import { RootStoreContext } from '../../../App';
import useTagListQuery from '../../../hooks/queries/useTagQuery';
import useTagClickHandler from '../../../hooks/useTagClickHandler';
import {
	kidTags,
	schoolTags,
	seniorTags,
	categoryTags,
	samsungTags,
} from '../../../data/categoryTagId';
import { useRemoveQueryParam } from '../../../hooks/useQueryParams';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { useLocation } from 'react-router-dom';

import HistoryWordsSection from './section/historyWordsSection/HistoryWordsSection';
import ProgramSection from './section/programSection/ProgramSection';

const SearchDetailBox = observer(
	({
		isDetailPanelOn,
		searchWordList,
		handleWordHistoryItemClick,
		removeSearchInput,
		deleteAllHistoryWords,
	}) => {
		const { authStore } = useContext(RootStoreContext);

		const location = useLocation();
		const searchParams = new URLSearchParams(location.search);
		const categoryRefId = 'categoryFilterWheel';

		const tagClickHandler = useTagClickHandler('unique');
		const { data: tags } = useTagListQuery();

		const removeQueryParam = useRemoveQueryParam();
		const queryParams = useQueryParams();
		const uuid = queryParams.get('uuid');

		const [isAdmin, setIsAdmin] = useState(false);
		const [isSenior, setIsSenior] = useState(false);
		const [isSamsung, setIsSamsung] = useState(false);
		const [recommendedTags, setRecommendedTags] = useState([]);
		const [selectedTag, setSelectedTag] = useState([]);

		useEffect(() => {
			const isSenior = authStore.auth?.role?.some(
				(role) => role.name === 'senior',
			);
			const isSamsung = authStore.auth?.role?.some(
				(role) => role.name === 'samsung',
			);
			const isAdmin = authStore.auth?.role?.some(
				(role) => role.name === 'admin',
			);
			setIsSenior(isSenior);
			setIsSamsung(isSamsung);
			setIsAdmin(isAdmin);
		}, [authStore.auth]);

		useEffect(() => {
			if (!tags) return;
			if (isSenior) {
				setRecommendedTags(seniorTags);
			} else {
				setRecommendedTags([
					...kidTags,
					...schoolTags,
					...categoryTags,
					...seniorTags,
				]);
			}
		}, [isSenior, tags]);

		const renderRecommendTags = () => {
			recommendedTags?.map((tag, index) => {
				return (
					<div
						className={checkTagContain(tag.id) !== -1 ? 'clicked' : ''}
						key={index}
						onClick={() => {
							tagClickHandler(tag, 'search');
						}}>
						{tag?.name}
					</div>
				);
			});
		};

		// 프로그램 태그에서 중복된 내용 지우기
		const alllProgramTags = isSamsung
			? [...samsungTags]
			: isSenior
			? [...seniorTags]
			: [...kidTags, ...schoolTags]; // 프로그램 영역에 넣을 태그들 넣기
		const uniqueTags = [...new Set(alllProgramTags.map((tag) => tag.name))];
		console.log('[uniqueTags]', uniqueTags);
		const programTags = uniqueTags.map((tagName) => {
			const { id } = alllProgramTags.find((tag) => tag.name === tagName);
			return { name: tagName, id };
		});

		const tagList = {
			프로그램: programTags,
			카테고리: [...categoryTags, isAdmin && { name: '유교전', id: 176 }],
			연령: [
				{ name: '5세', id: 77 },
				{ name: '6세', id: 78 },
				{ name: '7세', id: 79 },
			],
			월: [
				{ name: '3월', id: 109 },
				{ name: '4월', id: 110 },
				{ name: '5월', id: 111 },
				{ name: '6월', id: 112 },
				{ name: '7월', id: 113 },
				{ name: '8월', id: 114 },
				{ name: '9월', id: 115 },
				{ name: '10월', id: 116 },
				{ name: '11월', id: 117 },
				{ name: '12월', id: 118 },
				{ name: '1월', id: 107 },
				{ name: '2월', id: 108 },
			],
		};

		const checkTagContain = (_tagId) => {
			const currentSearchIds = searchParams.getAll('search');
			const index = currentSearchIds.indexOf(_tagId?.toString());
			return index;
		};

		const renderTags = (tagType) => {
			const tagsList = tagList[tagType];

			// console.log('[tagList]', tagList);

			return tagsList?.map((tag, index) => (
				<div
					className={checkTagContain(tag.id) !== -1 ? 'tags clicked' : 'tags'}
					key={index}
					onClick={() => {
						tagClickHandler(tag, 'search');
					}}>
					{tag.name}
				</div>
			));
		};

		return (
			<S.Container isClicked={isDetailPanelOn}>
				<S.LayerWrapper>
					<S.LeftLayer>
						<HistoryWordsSection
							titleContent={'최근 검색어'}
							isDetailPanelOn={isDetailPanelOn}
							searchWordList={searchWordList}
							handleWordHistoryItemClick={handleWordHistoryItemClick}
							removeSearchInput={removeSearchInput}
							deleteAllHistoryWords={deleteAllHistoryWords}
						/>
					</S.LeftLayer>
					<S.RightLayer>
						<ProgramSection
							titleContent={'프로그램'}
							isDetailPanelOn={isDetailPanelOn}
							contentData={renderTags('프로그램')}
						/>
						{!isSenior && (
							<>
								<ProgramSection
									titleContent={'연령'}
									isDetailPanelOn={isDetailPanelOn}
									contentData={renderTags('연령')}
								/>
								<ProgramSection
									titleContent={'월'}
									isDetailPanelOn={isDetailPanelOn}
									contentData={renderTags('월')}
								/>
								<ProgramSection
									titleContent={'카테고리'}
									isDetailPanelOn={isDetailPanelOn}
									contentData={renderTags('카테고리')}
								/>
							</>
						)}
					</S.RightLayer>
				</S.LayerWrapper>
			</S.Container>
		);
	},
);

export default SearchDetailBox;
