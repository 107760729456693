import { LikuMotion } from '../../../store/domain/LikuMotion';

const PlayerMqtt = (mqtt) => {
	const {
		publish,
		subscribe,
		unsubscribe,
		// addHandler,
		removeHandler,
	} = mqtt;
	let uuid = null;

	const setUuid = (_uuid) => {
		uuid = _uuid;
	};

	const init = (likuUuid) => {
		setUuid(likuUuid);
	};

	const setContentsConnect = (handler) => {
		const display = 'eyekiss';

		const likuMotion = new LikuMotion('greet_arm', display, '연결되었습니다');
		const payload = likuMotion.toPayload();

		console.log('setContentsConnect handler', handler);
		subscribe(`liku/${uuid}/json/result`, {
			handlerFunction: function () {
				console.log('handlerFunction@!!!!', handler);
				if (typeof handler === 'function') {
					handler();
				}
				removeHandler(`liku/${uuid}/json/result`, this.handlerFunction);
			},
			requiredParameter: 'topic',
		});
		publish(`liku/${uuid}/json/list`, payload);
		// publish(`song/${uuid}/json/result`, 'done');
	};

	const setContentsMode = (id, executionUuid, handler) => {
		subscribe(`liku/${id}/ackCheck`);
		subscribe(`liku/musical/${executionUuid}/check`);
		subscribe(`liku/musical/${executionUuid}`, {
			handlerFunction: handler,
			requiredParameter: 'payload',
		});
	};

	const unsetContentsMode = (id, executionUuid) => {
		unsubscribe(`liku/${id}/ackCheck`);
		unsubscribe(`liku/musical/${executionUuid}/check`);
		unsubscribe(`liku/musical/${executionUuid}`);

		unsubArucoMode();
		unsubTouchMode();
	};

	const isContentsReady = () => {
		publish(`liku/${uuid}/musical/ready`, '?');
	};

	const contentsCheck = (executionUuid, contentsUuid) => {
		publish(`liku/musical/${executionUuid}/check`, { contentsUuid });
	};

	const setArucoMode = (data, handler) => {
		let payload = null;
		if (data) payload = { ...data };
		else payload = 'stop';

		console.log('isAruco data', data);
		console.log('isAruco , handler', handler);
		if (handler) {
			subscribe(`liku/${uuid}/aruco/result`, {
				handlerFunction: handler,
				requiredParameter: 'payload',
			});
		}
		publish(`liku/${uuid}/robot/aruco`, payload);
	};

	const unsubArucoMode = () => {
		unsubscribe(`liku/${uuid}/robot/aruco`);
	};

	const setTouchMode = (data, handler) => {
		let payload = null;
		if (data) payload = data;
		else payload = 'stop';

		if (handler) {
			subscribe(`liku/${uuid}/touch`, {
				handlerFunction: handler,
				requiredParameter: 'payload',
			});
		}
		publish(`liku/${uuid}/robot/touch`, payload);
	};

	const unsubTouchMode = () => {
		publish(`liku/${uuid}/robot/touch`, 'stop');
		unsubscribe(`liku/${uuid}/touch`);
	};

	const setFacePhotos = (facePhotosHandler, wholePhotosHandler) => {
		subscribe(`liku/${uuid}/vis/image/database`, {
			handlerFunction: facePhotosHandler,
		});
		subscribe(`liku/${uuid}/vis/image/metaverse/whole`, {
			handlerFunction: wholePhotosHandler,
			requiredParameter: 'originPayload',
		});
	};

	/**
	 * 리쿠 상태 설정
	 * @message {string} photoType
	 *   - 'memory': 기억하고 있는 사진
	 *   - 'new': 사진찍기
	 */
	const requestFacePhoto = (message) => {
		publish(`liku/${uuid}/vis/image/metaverse`, message);
	};

	const unsetFacePhotos = () => {
		unsubscribe(`liku/${uuid}/vis/image/database`);
		unsubscribe(`liku/${uuid}/vis/image/metaverse/whole`);
	};

	// const responsesFacePhotoDB = (handler) => {
	// 	subscribe(`liku/${uuid}/vis/image/database`, {
	// 		handlerFunction: handler,
	// 		// requiredParameter: 'both',
	// 	});
	// };
	//
	// const responsesWholePhoto = () => {};

	// ------------------metaverse
	const metaverseImageDB = async (handler) => {
		subscribe(`liku/${uuid}/vis/image/database`, {
			handlerFunction: handler,
			requiredParameter: 'both',
		});
	};

	const metaverseWhole = async (handler) => {
		subscribe(`liku/${uuid}/vis/image/metaverse/whole`, {
			handlerFunction: handler,
			requiredParameter: 'both',
		});
	};

	const metaverseImageUser = async (handler) => {
		subscribe(`liku/${uuid}/vision/image/user`, {
			handlerFunction: handler,
			requiredParameter: 'both',
		});
	};

	const unsetMetaverse = () => {
		unsubscribe(`liku/${uuid}/vis/image/database`);
		unsubscribe(`liku/${uuid}/vis/image/metaverse/whole`);
		unsubscribe(`liku/${uuid}/vision/image/user`);
	};
	// ------------------metaverse

	// const musicalSet = async () => {
	// 	const topic = `liku/${uuid}/musical/set`;
	// 	const msg = { uuid, role: '나래이션' };
	// 	await publish(topic, msg);
	// };

	const setListenText = (handler) => {
		// interactive contents listen subscribe
		subscribe(`liku/${uuid}/json/transcript`, {
			handlerFunction: handler,
			requiredParameter: 'payload',
		});
		// { handlerFunction: handler });
	};

	const unsetListenText = () => {
		subscribe(`liku/${uuid}/json/transcript`);
	};

	const playMotion = (executionUuid, role, data) => {
		publish(`liku/musical/${executionUuid}/${role}`, data);
	};

	const defaultStop = () => {
		publish(`liku/${uuid}/user/stop`, 'stop');
	};

	const musicalStop = () => {
		publish(`liku/${uuid}/musical/stop`, 'stop');
	};

	return {
		init,
		setContentsConnect,
		setContentsMode,
		unsetContentsMode,
		isContentsReady,
		contentsCheck,

		setArucoMode,
		unsubArucoMode,

		setTouchMode,
		unsubTouchMode,

		setFacePhotos,
		requestFacePhoto,
		unsetFacePhotos,

		metaverseImageDB,
		metaverseWhole,
		metaverseImageUser,
		unsetMetaverse,

		setListenText,
		unsetListenText,

		playMotion,

		defaultStop,
		musicalStop,
	};
};

export default PlayerMqtt;
