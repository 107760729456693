import { makeAutoObservable, reaction, runInAction } from 'mobx';

import * as Cookie from '../../utils/Cookie';

import { Liku } from './Liku';

import {
	addLiku,
	deleteLiku,
	getLikuAll,
	getLikuInfo,
} from '../../services/apis/LikuApi';

import wakeup from '../../assets/json/controller/wakeup.json';
import sleep from '../../assets/json/controller/sleep.json';
import standup from '../../assets/json/controller/expression/StupChair.json';
import sitdown from '../../assets/json/controller/expression/SitDownChair.json';
import approach from '../../assets/json/controller/expression/ApproachApp.json';
import recede from '../../assets/json/controller/expression/RecedeApp.json';
import { ACTIONS, DISPLAYS } from '../../data/motion';
import { authApi } from '../../services/apis';

class LikuStore {
	rootStore;

	likus = [];
	thisLiku = null;

	isMusic = false;
	shuffleMusic = null;

	isControl = false;

	greetConfig = {};

	constructor(root) {
		makeAutoObservable(this);
		this.rootStore = root;

		this.mqttHandler = reaction(
			() => this.thisLiku,
			async (liku) => {
				if (liku) {
					console.log('reaction callback');
					this.rootStore.mqttStore.connect(this.connectInit.bind(this));
				} else {
					this.rootStore.mqttStore.disconnect();
				}
			},
		);
		reaction(
			() => this.rootStore.mqttStore.isConnected,
			(connected) => {
				if (connected) {
					console.log('connected');
				}
			},
		);
	}

	async connectInit() {
		if (!this.thisLiku || !this.thisLiku?.update) return;

		const likuInfo = await getLikuInfo(this.thisLiku.serial);

		this.thisLiku.update(likuInfo);

		this.rootStore.mqttStore.likuMqtt.init(
			this.thisLiku.uuid,
			this.jsonResultDone.bind(this),
			this.thisLiku.setVolume.bind(this.thisLiku),
			this.thisLiku.setLanguage.bind(this.thisLiku),
			this.thisLiku.setMotionStatus.bind(this.thisLiku),
			this.thisLiku.setPersona.bind(this.thisLiku),
		);
		this.rootStore.mqttStore.playerMqtt.init(this.thisLiku.uuid);

		Cookie.set('liku', this.thisLiku.serial);
		let domain =
			process.env.NODE_ENV === 'production' ? '.torooc.com' : '.localhost';

		Cookie.set('likuUuid', this.thisLiku.uuid, {
			domain: domain,
		});
	}

	stop() {
		this.rootStore.mqttStore.likuMqtt.stop();
	}

	playJson(payload) {
		console.log(this.thisLiku.motionStatus);
		if (this.thisLiku.motionStatus !== 'sleeping') {
			console.log('payload', payload);
			if (payload?.action?.action_name === 'SelfStandUp') {
				payload = standup;
			} else if (payload?.action?.action_name === 'SelfSitDown') {
				payload = sitdown;
			} else if (payload?.action?.action_name === 'ApproachApp') {
				payload = approach;
			} else if (payload?.action?.action_name === 'RecedeApp') {
				payload = recede;
			}

			if (payload?.exp) {
				this.rootStore.mqttStore.likuMqtt.playExpression(payload);
			} else {
				if (payload?.action?.action_name === 'defaultArray') {
					const randomIndex = Math.floor(
						Math.random() * ACTIONS.defaultArray.action.length,
					);
					payload.action.action_name = ACTIONS.defaultArray.action[randomIndex];
				}
				if (payload?.display?.display_name === 'defaultArray') {
					const randomList = Object.keys(DISPLAYS).filter(
						(display) => display !== 'defaultArray',
					);
					const randomIndex = Math.floor(Math.random() * randomList.length);
					payload.display.display_name = randomList[randomIndex];
				}
				if (payload?.speech?.TTS === '') {
					payload.speech.TTS = '';
				}

				if (!Array.isArray(payload)) {
					payload = { data: [payload] };
				} else {
					payload = { data: payload };
				}

				this.rootStore.mqttStore.likuMqtt.playMotion(payload, (e) => {
					const result =
						e.includes('/json/result') === true ? 'done' : undefined;
					if (result) {
						this.setIsMusic(false);
					}
				});
			}
		} else {
			alert('리쿠가 잠들어있어요.\n리쿠를 깨운 후 다시 시도해주세요.');
		}
	}

	jsonResultDone(topic, payload) {
		console.log(`jsonResultDone!! ( ${topic} : ${payload} )`);
		if (this.isMusic) {
			const isShuffle = localStorage.getItem('isShuffle') === 'true';
			if (isShuffle) {
				this.shuffleMusic = Math.random();
			} else {
				this.shuffleMusic = null;
			}
		} else if (this.isControl) {
			this.rootStore.motionStore.isNext();
		}
	}

	async loadLiku() {
		const id = this.rootStore.authStore.auth.id;
		const likus = await getLikuAll(id);
		runInAction(() => {
			this.likus = likus;
		});

		const recentLiku = localStorage.getItem('liku');
		if (recentLiku && likus?.includes(recentLiku)) {
			this.setThisLiku(recentLiku);
		}
	}

	setThisLiku(liku = this.thisLiku) {
		if (this.thisLiku === liku) {
			if (this.auth) {
				localStorage.removeItem('liku');
			}
			this.thisLiku = null;
		} else {
			localStorage.setItem('liku', liku);
			this.thisLiku = new Liku(this, liku);
		}
	}

	async addLiku(liku) {
		const id = this.rootStore.authStore.auth.id;
		const result = await addLiku(id, liku);
		// const id = 1;

		if (result?.detail?.includes('already')) {
			alert('이미 등록된 리쿠입니다. 등록된 리쿠를 삭제 후 다시 등록해주세요.');
		} else if (result?.detail?.includes('not found')) {
			alert('리쿠 시리얼을 다시 확인해주세요.');
		} else if (!Array.isArray(result?.detail)) {
			console.log('result', result);
			this.likus = result;

			// const addedLiku = await authApi.get(`/likus?serial=${liku}`);
			// console.log('add Liku!!');
			// const topic = `liku/${addedLiku.data?.uuid}/robot/custom/set`;
			// this.rootStore.mqttStore.connect(() => {
			// 	return {
			// 		type: 'add',
			// 		likus: [addedLiku.data?.uuid],
			// 		topic,
			// 		message: 'all',
			// 	};
			// });
			// setTimeout(() => {
			// 	this.rootStore.mqttStore.disconnect();
			// }, 1000);
			return true;
		} else {
			alert('문제가 발생겼습니다. 관리자에게 문의해주세요.\n', result?.detail);
		}

		return false;
	}

	async deleteLiku(liku) {
		const id = this.rootStore.authStore.auth.id;
		// // const id = 1;
		// const deletedLiku = await authApi.get(`/likus?serial=${liku}`);

		// const topic = `liku${deletedLiku.data?.uuid}/robot/custom/set`;
		// this.rootStore.mqttStore.connect(() => {
		// 	return {
		// 		type: 'delete',
		// 		likus: [deletedLiku.data?.uuid],
		// 		topic,
		// 		message: 'all',
		// 	};
		// });

		// setTimeout(() => {
		// 	this.rootStore.mqttStore.disconnect();
		// }, 1000);

		const result = await deleteLiku(id, liku);
		this.rootStore.mqttStore.disconnect();
		if (Array.isArray(result)) {
			runInAction(() => {
				this.likus = result;
			});

			return true;
		}

		return false;
	}

	setVolume(volume) {
		this.rootStore.mqttStore.likuMqtt.setVolume(volume);
	}

	/**
	 * 리쿠 상태 설정
	 * @param {string} motion-state
	 *   - 'sleep': 자는중
	 *   - 'active': 깨우기
	 */
	setMotionState(value) {
		if (this.thisLiku.motionState !== value) {
			let expression;
			if (value === 'sleep') expression = sleep;
			else if (value === 'active') expression = wakeup;

			this.rootStore.mqttStore.likuMqtt.playExpression(expression);
		}
	}

	setIsMusic(value = !this.isMusic) {
		this.isMusic = value;
	}

	setIsControl(value = !this.isControl) {
		this.isControl = value;
	}

	dispose() {
		this.mqttHandler();
	}

	// async findLikuByUuid(uuid) {
	// 	return this.likus.find((liku) => liku.uuid === uuid);
	// }
	//
	// async setGreetConfig(topic, data) {
	// 	try {
	// 		const uuid = topic.split('/')[1];
	// 		const liku = await LikuStore.findLikuByUuid(uuid);
	//
	// 		this.greetConfig[liku.serial] = data !== 'None' ? data : {};
	// 	} catch (error) {
	// 		console.error(error);
	// 	}
	// }
	//
	// /**
	//  * 첫인사 관련 config publish입니다.
	//  * @param {string} liku
	//  * @param {object} data
	//  */
	// async setGreetConfigPub(liku, data) {
	// 	const topic = `liku/${liku.uuid}/config`;
	// 	await MqttStore.publishMessage(topic, data);
	// }
	//
	// async getGreetConfigPub() {
	// 	for (const liku of this.likus) {
	// 		const uuid = liku.uuid;
	// 		const topic = `liku/${uuid}/config/get`;
	// 		await MqttStore.publishMessage(topic, 'get');
	// 	}
	// }
	//
	// async greetConfigSub() {
	// 	for (const liku of this.likus) {
	// 		const uuid = liku.uuid;
	// 		const topic = `liku/${uuid}/config/response`;
	// 		await MqttStore.subscribeToTopic(topic);
	// 	}
	// }
}

export default LikuStore;
