import { Cookies } from 'react-cookie';

const cookies = new Cookies();
// console.log('dotenv', process.env.REACT_APP_NODE_ENV);
// .env 환경변수 쓰다 바꿔가며 빌드하는것 보다 그냥 자동으로 읽고 빌드 되는게 편해서 문제있는김에 바꿈
const currentDomain = window.location.hostname;

let domain = '';

if (currentDomain.includes('torooc.com')) {
	domain = '.torooc.com';
} else if (currentDomain.includes('torooc.net')) {
	domain = '.torooc.net';
} else {
	domain = '.localhost';
}

export const set = (name, value, option) => {
	if (value !== undefined)
		return cookies.set(name, value, {
			/** 배포할때 다른 torooc.com 하위 도메인에서도 쿠키를 전달 할 수 있게 함 */
			domain: domain,
			...option,
		});
};

export const get = (name) => {
	return cookies.get(name);
};

export const remove = (name, option) => {
	if (name !== 'searchWordListHistory') {
		return cookies.remove(name, { ...option, domain });
	}
};

export const getAll = () => {
	return cookies.getAll();
};

export const removeAll = () => {
	let cookieAll = getAll();
	for (let cookie of Object.keys(cookieAll)) {
		remove(cookie, { path: '/', maxAge: 43200 });
	}
};

export const refresh = () => {
	let cookieAll = getAll();
	for (let cookie of Object.keys(cookieAll)) {
		if (get(cookie) === 'searchWordListHistory') {
			continue;
		}
		set(cookie, cookieAll.cookie, { path: '/', maxAge: 43200 });
	}
};
