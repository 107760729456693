import React from 'react';
import './styles/fonts.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { observer } from 'mobx-react';

import { ThemeProvider } from 'styled-components';

import { GlobalStyles } from './styles/index';

import RootStore from './store';

import Router from './routers/Router';
import { getTheme as theme } from './styles/theme';

export const RootStoreContext = React.createContext(null);

const App = observer(() => {
	const rootStore = React.useMemo(() => new RootStore(), []);

	const currentTheme = theme(
		rootStore.modeStore.isDarkMode,
		rootStore.modeStore.isBigMode,
	);

	const currentDomain = window.location.hostname;

	let domain = '';

	if (currentDomain.includes('torooc.com')) {
		domain = '.torooc.com';
	} else if (currentDomain.includes('torooc.net')) {
		domain = '.torooc.net';
	} else {
		domain = '.localhost';
	}
	const isLocalhost = domain === '.localhost';

	return (
		<QueryClientProvider client={new QueryClient()}>
			<RootStoreContext.Provider value={rootStore}>
				<ThemeProvider theme={currentTheme}>
					<GlobalStyles />
					<Router />
				</ThemeProvider>
			</RootStoreContext.Provider>
			<ReactQueryDevtools
				initialIsOpen={false}
				toggleButtonProps={{
					style: {
						display: isLocalhost ? 'block' : 'none',
					},
				}}
			/>
		</QueryClientProvider>
	);
});

export default App;
