import styled, { css, keyframes } from 'styled-components';

import { Box } from '../Dock.style';

import music from '../../../assets/image/music/music.webp';
import music2x from '../../../assets/image/music/music@2x.webp';
import music3x from '../../../assets/image/music/music@3x.webp';

import controller from '../../../assets/image/controller/controller.webp';
import controller2x from '../../../assets/image/controller/controller@2x.webp';
import controller3x from '../../../assets/image/controller/controller@3x.webp';

import favorites from '../../../assets/image/favorites/favorites.webp';
import favorites2x from '../../../assets/image/favorites/favorites@2x.webp';
import favorites3x from '../../../assets/image/favorites/favorites@3x.webp';

import unlink from '../../../assets/image/unlink/unlink.webp';
import unlink2x from '../../../assets/image/unlink/unlink@2x.webp';
import unlink3x from '../../../assets/image/unlink/unlink@3x.webp';

const music_list = [music, music2x, music3x];
const controller_list = [controller, controller2x, controller3x];
const favorites_list = [favorites, favorites2x, favorites3x];
const unlink_list = [unlink, unlink2x, unlink3x];

export { music_list, controller_list, favorites_list, unlink_list };

export const Content = styled(Box)`
	overflow: visible;
	padding: 1.2rem 3rem 1.2rem 1.6rem;
	${({ theme }) => theme.common.flex('row', 'center', 'space-between')};
	button {
		cursor: pointer;
	}
`;

export const LikuWrap = styled.div`
	position: relative;
	${({ theme }) => theme.common.flex('column', 'center', 'center')};
`;

const ldio = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(90deg); }
`;

// export const Mind = styled.div`
// 	display: inline-block;
// 	overflow: hidden;
// 	background: transparent;
//
// 	border-radius: 32px;
// 	box-shadow: inset 0 2px 8px 0 rgba(0, 0, 0, 0.08);
// 	margin-right: 2.8rem;
// 	${({ theme }) => theme.common.wh('6.4rem')};
//
// `;

export const Mind = styled.div`
	position: relative;
	display: inline-block;
	overflow: hidden;
	background: transparent;
	transform: translateZ(0) scale(1);
	backface-visibility: hidden;
	transform-origin: 0 0;
	overflow: hidden;

	border-radius: 32px;
	box-shadow: inset 0 2px 8px 0 rgba(0, 0, 0, 0.08);
	margin-right: 2.8rem;
	${({ theme }) => theme.common.wh('6.4rem')};

	> div {
		position: absolute;
		width: 6.4rem;
		height: 6.4rem;
		top: 0;
		left: 0;
	}

	div:nth-child(1) {
		background: #e9a00c;
	}

	div:nth-child(2) div {
		position: absolute;
		left: -6.4rem;
		width: 19.2rem;
		height: 19.2rem;
		opacity: 0.4;
		transform-origin: 9.6rem 9.6rem;
		animation: ${ldio} linear infinite;
	}

	div:nth-child(2) div:nth-child(1) {
		top: -16rem;
		border-radius: 7.2rem;
		background: #a9f6ff;
		animation-duration: 1.009090909090909s;
	}

	div:nth-child(2) div:nth-child(2) {
		top: -16.64rem;
		border-radius: 7.6rem;
		background: #0064da;
		animation-duration: 1.5151515151515151s;
	}

	div:nth-child(2) div:nth-child(3) {
		top: -17.28rem;
		border-radius: 8rem;
		background: #003ff4;
		animation-duration: 3.0303030303030303s;
	}
`;

export const DisconnectButton = styled.button`
	position: absolute;
	top: 0;
	left: 0;
	border: none;
	border-radius: 32px;
	padding: 0.85rem 0 0.8rem;
	opacity: 0.75;
	background-color: rgba(255, 255, 255, 1);
	${({ theme }) => theme.common.flex('column', 'center', 'space-around')};
	${({ theme }) => theme.common.wh('6.4rem')};

	img {
		display: block;
		${({ theme }) => theme.common.wh('50%')};
	}
`;

export const Serial = styled.div`
	position: absolute;
	bottom: calc(100% + 0.4rem); /* Serial을 Mind 위에 위치시킴 */
	left: calc(50% - 1.4rem); /* 좌우 중앙 정렬을 위해 50%로 설정 */
	transform: translateX(-50%); /* 위치 조정을 위해 -50%로 설정 */
	padding: 0.3rem 1.2rem;
	border-radius: 4px;
	box-shadow: inset 0 2px 8px 0 rgba(0, 0, 0, 0.08);

	font-family: NotoSansKR-Regular;
	font-size: 1.2rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1;
	letter-spacing: normal;
	text-align: center;
	color: #fff;

	background-color: ${({ theme }) => theme.color.title};
`;

export const Controls = styled.div`
	display: flex;
	gap: 1.6rem;
	flex-flow: row nowrap;
`;

export const Line = styled.div`
	margin: 0 1.5rem;
	background-color: #ebebe9;
	${({ theme }) => theme.common.wh('0.2rem', '4.8rem')};
`;

export const Menu = styled.button`
	border: none;
	border-radius: 12px;
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
	${({ click }) => click && 'filter: brightness(50%)'};
	${({ theme }) => theme.common.wh('6.4rem')};

	&.music,
	&.controller {
		padding: 1.5rem;
	}

	&.music {
		background-color: #ff5701;
	}

	&.controller {
		background-color: #6e78f7;
	}

	&.favorites {
		background-color: #fff;
	}
`;

export const Button = styled.button`
	padding: 0 2rem;
	border: none;
	border-radius: 24px;
	background-color: #f7f7f9;
	overflow: hidden;
	${({ theme }) => theme.common.flex('row', 'center', 'space-between')};
`;
