const LikuMqtt = (mqtt, _heartbeat, getInitNum, setInitNum) => {
	const {
		publish,
		subscribe,
		// unsubscribe,
		// addHandler,
		removeHandler,
	} = mqtt;
	const heartbeat = _heartbeat;
	let uuid = null;
	let num = -1;

	const setUuid = (_uuid) => {
		uuid = _uuid;
	};

	const init = (
		uuid,
		jsonResultDone,
		setVolume,
		setLanguage,
		setMotionStatus,
	) => {
		// let num = getInitNum();
		publish('liku/3d7a0fa9-53b6-4bdc-99c1-babcc6860409/robot/serial', {
			message: 'data',
		});

		if (num < 0) {
			num++;
			// setInitNum(num++);
			setUuid(uuid);

			subscribe(`liku/${uuid}/ackCheck`);
			subscribe(`liku/${uuid}/heartbeat`, {
				handlerFunction: heartbeat.setHeartBeat,
			});
			subscribe(`liku/${uuid}/json/result`, {
				handlerFunction: jsonResultDone,
				requiredParameter: 'both',
			});
			subscribe(`liku/${uuid}/volume`, {
				handlerFunction: setVolume,
				requiredParameter: 'payload',
			});
			subscribe(`liku/${uuid}/language/response`, {
				handlerFunction: setLanguage,
				requiredParameter: 'payload',
			});
			subscribe(`liku/${uuid}/motion-status`, {
				handlerFunction: setMotionStatus,
				requiredParameter: 'payload',
			});

			subscribe(`liku/${uuid}/volume`, {
				handlerFunction: () => heartbeat.popAck('volume'),
				requiredParameter: 'topic',
			});
			subscribe(`liku/${uuid}/language/response`, {
				handlerFunction: () => heartbeat.popAck('language'),
				requiredParameter: 'topic',
			});
			subscribe(`liku/${uuid}/motion-status`, {
				handlerFunction: () => heartbeat.popAck('motion'),
				requiredParameter: 'topic',
			});
			subscribe(`liku/${uuid}/motion-status`, {
				handlerFunction: heartbeat.setIsHeartBeat,
			});

			getAllStatus(true);
		}
	};

	const getAllStatus = (init = false) => {
		if (init) {
			heartbeat.setHeartBeatInterval();
		}

		getVolume();
		getLanguage();
		getMotionStatus();
	};

	const getVolume = () => {
		heartbeat.addAck('volume');
		publish(`liku/${uuid}/volume/get`, { volume: 'get' });
	};

	const getLanguage = () => {
		heartbeat.addAck('language');
		publish(`liku/${uuid}/language/get`, { language: 'data' });
	};

	const getMotionStatus = () => {
		heartbeat.addAck('motion');
		publish(`liku/${uuid}/motion-status/get`, { message: 'data' });
	};

	const setVolume = (volume) => {
		publish(`liku/${uuid}/volume/set`, { volume: `${volume}` });
	};

	/**
	 * 리쿠 언어 설정
	 * @param {string} language
	 *   - 'ko': 한국어
	 *   - 'en': 영어
	 */
	const setLanguage = (language, isPlayerHandelr) => {
		publish(`liku/${uuid}/language`, { language });

		if (isPlayerHandelr) {
			subscribe(`liku/${uuid}/language/response`, {
				handlerFunction: function (topic) {
					isPlayerHandelr(topic);
					removeHandler(`liku/${uuid}/language/response`, this.handlerFunction);
				},
				requiredParameter: 'payload',
			});
		}
	};

	const stop = () => {
		publish(`liku/${uuid}/user/stop`, 'stop');
	};

	const playMotion = (payload, handler) => {
		if (handler) {
			subscribe(`liku/${uuid}/json/result`, {
				handlerFunction: handler,
				requiredParameter: 'topic',
			});
		}
		publish(`liku/${uuid}/json/list`, payload);
	};

	const playExpression = (payload) => {
		publish(`liku/${uuid}/exp`, payload);
	};

	return {
		init,
		// deleteFirstHeartBeat,
		getAllStatus,
		getVolume,
		getMotionStatus,
		getLanguage,

		setVolume,
		setLanguage,

		stop,
		playMotion,
		playExpression,
	};
};

export default LikuMqtt;
